<template>
	<div>
		<div class="container darkBack">
			<div class="font20 whiteFont">体育企业信息</div>
			<div class="enterprise">
				<table>
					<thead>
						<th style="width:100%;">企业名称</th>
						<!-- <th>所属行业</th> -->
					</thead>
				</table>
				<vueSeamlessScroll :data="enterpriseInfo" class="seamlessWarp" :class-option="classOption">
					<table>
						<tr v-for="(item,index) in enterpriseInfo" :key="index">
							<td style="width:100%;text-align:center;">{{item.title}}</td>
							<!-- <td style="text-align:center;">{{item.type_text}}</td> -->
						</tr>
					</table>
				</vueSeamlessScroll>
			</div>
		</div>
	</div>
</template>

<script>
	import vueSeamlessScroll from 'vue-seamless-scroll'
	import bus from "../../../assets/js/bus"
	export default {
		components:{
			vueSeamlessScroll
		},
		data() {
			return{
				enterpriseInfo:[],
				tableData:[]
			}
		},
		methods:{
			
		},
		computed: {
		    classOption () {
		       return {
		        step: 0.5, // 数值越大速度滚动越快
		        limitMoveNum: 11, // 开始无缝滚动的数据量 this.dataList.length
		        hoverStop: true, // 是否开启鼠标悬停stop
		        direction: 1, // 0向下 1向上 2向左 3向右
		        openWatch: true, // 开启数据实时监控刷新dom
		        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
		        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
		        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
		      }
		    }
		},
		mounted () {
			let that = this;
			bus.$on('enterpriseInfo',function(res){
				// console.log(res)
				that.enterpriseInfo = res;
			})
		},
		destroyed () {
			bus.$off('enterpriseInfo')  
		},
	}
</script>

<style scoped>
	.container{
		width: 4.5rem;
		height: 4.79rem;
		padding: 0.2rem;
		position: relative;
	}
	.enterprise{
		height: 3.8rem;
		overflow: hidden;
	}
	table{
		width: 4.2rem;
		margin-top: 0.2rem;
	}
	table > thead{
		color: #2796C4;
		line-height: 0.3rem;
	}
	table > thead >td:first-child{
		width: 73%;
	}
	table > tr{
		color: #7EA7E2;
	}
	.seamlessWarp{
		overflow: hidden;
		color: #999999;
		margin-top: 0.1rem;
	}
</style>